import { greenRing, redRing, yellowRing } from '@app/assets/images';

export const convertProbablityIntoPercentage = (prob: number, item?: string) => {
  const probability = (item === 'Skin Without Pathology' ? 100 - prob * 100 : prob * 100).toFixed(2);
  return parseFloat(probability);
};

export const getRingImage = (prob: number, item: string) => {
  const percentage = convertProbablityIntoPercentage(prob, item);

  if (percentage >= 80 && percentage <= 100) {
    return redRing;
  } else if (percentage >= 60 && percentage < 80) {
    return yellowRing;
  } else {
    return greenRing;
  }
};
